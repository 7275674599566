<script>
  export let product;
  export let buy_handler;
  import { onMount } from "svelte";

  const option_id = (name, id) => {
    return `${name}-${id.replace(/=/g, '')}`
  }

  onMount(() => {
    jQuery(document).trigger("svelte-product-card-rendered", [product.id]);
  });
  
</script>

<div class="filter-list-item {window.SHOPHelper.cssClass}">
  <div class="product shopify-buy-frame shopify-buy-frame--product shopify-buy__layout-vertical"
      style="max-width: 280px">

        <div class="shopify-buy__product__variant-img-wrapper shopify-buy__product__variant-img-wrapper--desktop" data-shop-gallery-carousel-desktop={product.id}>
        {#if window.SHOPHelper.disableGallery === "True" }
          <img class="shopify-buy__product__variant-img" alt={product.title} src={product.images[0].originalSrc}>
        {:else}
          <!-- Desktop -->
          {#each product.images as image}
            <a href="{image.originalSrc}" class="shopify-buy__product__img-link">
              <img class="shopify-buy__product__variant-img" alt={product.title} src={image.originalSrc}>
              <svg class="shopify-buy__product__variant-img-zoom" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle class="shopify-buy__product__variant-img-zoom__el" cx="21.5416" cy="21.5416" r="13.7083" stroke-width="2"/>
                <path class="shopify-buy__product__variant-img-zoom__el" d="M39.1667 39.1667L33.2917 33.2917" stroke-width="2" stroke-linecap="round"/>
                <path class="shopify-buy__product__variant-img-zoom__el" d="M21 16V28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path class="shopify-buy__product__variant-img-zoom__el" d="M27 22L15 22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>    
            </a>
          {/each} 
        {/if}
      </div>

      <div class="shopify-buy__product__variant-img-wrapper shopify-buy__product__variant-img-wrapper--mobile" data-shop-gallery-carousel-mobile={product.id}>
          <!-- Mobile -->
          {#each product.images as image}
            <img class="shopify-buy__product__variant-img" alt={product.title} src={image.originalSrc}>
          {/each}
      </div>

      <div class="shopify-buy__product__top-details">
        <h1 class="shopify-buy__product__title">{product.title}</h1>
        <p class="shopify-buy__product__title-underline"></p>

        <div class="shopify-buy__product-description">{@html product.descriptionHtml}</div>
      </div>

      <div class="shopify-buy__option-select-box">
        {#each product.options as option}
        {#if option.name !== "Title" }
        <div>
            <p>{option.name}</p>

            <div class="shopify-buy__option-select-wrapper">
                <select id={option_id(option.name, product.id)} class="shopify-buy__option-select__select">
                    {#each option.values as value}
                    <option {value}>{value}</option>
                    {/each}
                </select>
            </div>
        </div>
        {/if}
        {/each}
      </div>

      <div class="shopify-buy__product__price-wrapper">
          <p class="shopify-buy__product__price">
              <span>{product.price.currencySymbol}</span><span>{parseFloat(product.price.amount || "0").toFixed(2)}</span>
          </p>
      </div>

      <div class="shopify-buy__btn-wrapper">
          <button data-product_id={product.id} class="shopify-buy__btn" on:click={buy_handler}>Add to cart</button>
      </div>
  </div> <!-- end of product -->
</div> <!-- end of filter-list-item -->
