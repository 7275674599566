<script>
  export let checkout;
  export let is_visible = true;
  export let checkout_url;
  export let items = [];
  export let close_handler;
  export let increment_handler;
  export let decrement_handler;
  export let set_quantity_handler;
  export let checkout_handler;

  const options_str = (item) => {
    const values = item.variant.selectedOptions.map(o => o.value);
    if (values.length) {
      return values.join(' / ')
    }
    return ''
  }

  const get_currency_symbol = (currencyCode) => {
    let currencySymbol = "";

    switch (currencyCode) {
        case "GBP":
        currencySymbol = "£";
        break;
        case "EUR":
        currencySymbol = "€";
        break;
        default:
        currencySymbol = "$";
        break;
    }

    return currencySymbol;
  }
</script>

<!-- shopping cart -->
<div
  class="shopify-buy-cart-wrapper shopify-buy-frame shopify-buy-frame--cart is-initialized"
  class:is-active={is_visible}>
  <div class="shopify-buy__cart">
    <div class="shopify-buy__cart__header">
      <h2 class="shopify-buy__cart__title mb-0">Cart</h2>
      <button class="shopify-buy__btn--close" on:click={close_handler}>
        <span>× </span>
        <span class="visuallyhidden">Close </span>
      </button>
    </div>
    <div class="shopify-buy__cart-scroll">
      <div class="shopify-buy__cart-items">
        {#each items as item}
          <div id={item.id} class="shopify-buy__cart-item">
            <div
              class="shopify-buy__cart-item__image"
              style="background-image: url({item.variant.image
                .originalSrc});" />
            <span class="shopify-buy__cart-item__title">
              {item.title}
            </span>
            <div class="shopify-buy__cart-item__variant-title">
              {options_str(item)}
            </div>
            <div class="shopify-buy__cart-item__price-and-discounts">
              <div class="shopify-buy__cart-item__price">
                {get_currency_symbol(item.variant.price.currencyCode)}
                {Number(item.variant.price.amount).toFixed(2)}
              </div>
            </div>
            <div class="shopify-buy__quantity-container clearfix">
              <button
                class="shopify-buy__btn--seamless shopify-buy__quantity-decrement"
                type="button"
                data-variant_id={item.id}
                data-quantity={item.quantity}
                on:click={decrement_handler}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                  <path d="M4 7h8v2H4z" />
                </svg>
                <span class="visuallyhidden">Decrement </span>
              </button>
              <input
                class="shopify-buy__quantity shopify-buy__cart-item__quantity-input"
                type="number"
                min="0"
                aria-label="Quantity"
                value={item.quantity}
                data-variant_id={item.id}
                on:change={set_quantity_handler} />
              <button
                class="shopify-buy__btn--seamless shopify-buy__quantity-increment"
                type="button"
                data-variant_id={item.id}
                data-quantity={item.quantity}
                on:click={increment_handler}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                  <path d="M12 7H9V4H7v3H4v2h3v3h2V9h3z" />
                </svg>
                <span class="visuallyhidden">Increment </span>
              </button>
            </div>
          </div>
        {/each}
      </div>
    </div>
    <div class="shopify-buy__cart-bottom">
      <p class="shopify-buy__cart__subtotal__text">Subtotal</p>
      <p class="shopify-buy__cart__subtotal__price">
        {#if checkout && checkout.lineItems.length > 0}
          {get_currency_symbol(checkout.subtotalPrice.currencyCode)}
          {Number(checkout.subtotalPrice.amount).toFixed(2)}
        {/if}
      </p>
      <p class="shopify-buy__cart__notice">
        Shipping and discount codes are added at checkout.
      </p>
      <button
        class="shopify-buy__btn shopify-buy__btn--cart-checkout"
        type="button"
        on:click={checkout_handler}>
        Checkout
      </button>
    </div>
  </div>
</div>
<!-- end of shopping cart -->
