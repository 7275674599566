<script>
    import { onMount } from "svelte";
  
    export let active_filters = {};
    export let product_types = [];
    export let product_options = [];

    let gender_page_list = window.SHOPHelper.genderMenu; 
  
    let show_types = false;
    let show_gender = false;
    let show_colors = false;
    let show_sizes = false;
    let show_reset = false;
  
    const handle_types_click = (e) => {
      show_types = !show_types;
      show_gender = false;
      show_colors = false;
      show_sizes = false;
    }
  
    const handle_gender_click = (e) => {
      show_types = false;
      show_gender = !show_gender;
      show_colors = false;
      show_sizes = false;
    }

    const handle_colors_click = (e) => {
      show_types = false;
      show_gender = false;
      show_colors = !show_colors;
      show_sizes = false;
    }
  
    const handle_sizes_click = (e) => {
      show_types = false;
      show_gender = false;
      show_colors = false;
      show_sizes = !show_sizes;
    }
  
    const is_filters_include = (kind, value) => {
      if (kind in active_filters && active_filters[kind].includes(value)) {
        return true;
      }
  
      return false;
    }
  
    const update_filters = (kind, value) => {
      if (is_filters_include(kind, value)) {
        active_filters[kind] = active_filters[kind].filter(t => t !== value)
      } else {
        active_filters[kind] = kind in active_filters ? active_filters[kind] : [];
        active_filters[kind] = [...active_filters[kind], value];
      }
    }
  
    const change_types = (e) => {
      const value = e.target.value;
      return update_filters('types', value);
    };

    const change_gender = (e) => {
      const value = e.target.value;
      return update_filters('gender', value);
    };
  
    const change_colors = (e) => {
      const value = e.target.value;
      return update_filters('colors', value);
    };
  
    const change_sizes = (e) => {
      const value = e.target.value;
      return update_filters('sizes', value);
    };
  
    const split_name = (name) => {
        return name.split(' ').join('_').toLowerCase();
    }

    const reset_filters = () => {
      active_filters = {};
      let checkboxs = document.querySelectorAll('.filter-list__elements input[type=checkbox]');
      checkboxs.forEach(e => e.checked = false);
      show_types = false;
      show_genders = false;      
      show_colors = false;
      show_sizes = false;
    }

    const handle_filter_change = () => {
      if(Object.keys(active_filters).length > 0){
        show_reset = true;
        if(Object.values(active_filters).every(x => x === null || x.length === 0)){
          show_reset = false;
        }
      } else{
        show_reset = false;
      }
    }

    $: if(active_filters) handle_filter_change();
</script>

{#if window.SHOPHelper.hasFilters === "True"} 
<section class="producttabs ">
  <div class="filter-list sp filter-list__checkboxs">
    <div class="containe">
      <div class="row">
        <div class="col-12">
          <h2 class="filter-list__filterby">Filter by:</h2>
        </div>
      </div>

      <div class="filter-list__elements">
        <!-- clothing types -->
        <div class="wrapper">
          <div class="options options--clothing-types" class:show={show_types}>
            {#each product_types as kind, idx}
              <div class="elem">
                <input
                  type="checkbox"
                  id="filter-list-elements__option-{split_name(kind)}"
                  name="filter-list-elements__option-{split_name(kind)}"
                  value={kind}
                  on:click={change_types} />
                <label for="filter-list-elements__option-{split_name(kind)}">{kind}</label>
              </div>
            {/each}
          </div>

          <p
            class="options__title"
            class:upper-z={show_types}
            data-toggle="types"
            on:click={handle_types_click}>
            Clothing type
            <svg
              class:rotate={show_types}
              data-shop-filter-svg="1"
              width="10"
              height="9"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 0H0L5 10L10.5 0Z" fill="black" />
            </svg>
          </p>
        </div>
        <!-- end of clothing types -->

        <!-- gender -->
        <div class="wrapper">
          <div class="options options--gender" class:show={show_gender}>
            {#each gender_page_list as page, idx}
              <div class="elem">
                <a href={page.url} class="label">{page.name}</a>
              </div>
            {/each}
          </div>

          <p
            class="options__title"
            class:upper-z={show_gender}
            data-toggle="gender"
            on:click={handle_gender_click}>
            Gender
            <svg
              class:rotate={show_gender}
              data-shop-filter-svg="2"
              width="10"
              height="9"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 0H0L5 10L10.5 0Z" fill="black" />
            </svg>
          </p>
        </div>
        <!-- end of gender -->

        <!-- colors -->
        <!-- 
        <div class="wrapper">
          <div class="options" class:show={show_colors}>
            {#if product_options.Colour}
              {#each product_options.Colour as color, idx}
                <div class="elem">
                  <input
                    type="checkbox"
                    id="filter-list-elements__option-{split_name(color)}"
                    name="filter-list-elements__option-{split_name(color)}"
                    value={color}
                    on:click={change_colors} />
                  <label for="filter-list-elements__option-{split_name(color)}"
                    >{color}</label>
                </div>
              {/each}
            {/if}
          </div>

          <p
            class="options__title"
            class:upper-z={show_colors}
            data-toggle="colors"
            on:click={handle_colors_click}>
            Colour
            <svg
              class:rotate={show_colors}
              data-shop-filter-svg="2"
              width="10"
              height="9"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 0H0L5 10L10.5 0Z" fill="black" />
            </svg>
          </p>
        </div>
        -->
        <!-- end of colors -->

        <!-- sizes -->
        <!--           
        <div class="wrapper">
          <div class="options" class:show={show_sizes}>
            {#if product_options.Size}
              {#each product_options.Size as size, idx}
                <div class="elem">
                  <input
                    type="checkbox"
                    id="filter-list-elements__option-{split_name(size)}"
                    name="filter-list-elements__option-{split_name(size)}"
                    value={size}
                    on:click={change_sizes} />
                  <label for="filter-list-elements__option-{split_name(size)}">{size}</label>
                </div>
              {/each}
            {/if}
          </div>

          <p
            class="options__title"
            class:upper-z={show_sizes}
            data-toggle="sizes"
            on:click={handle_sizes_click}>
            Size
            <svg
              class:rotate={show_sizes}
              data-shop-filter-svg="3"
              width="10"
              height="9"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 0H0L5 10L10.5 0Z" fill="black" />
            </svg>
          </p>
        </div>
        -->
        <!-- end of sizes -->

        <div class="wrapper__reset wrapper" class:show-reset="{show_reset}">
          <span title="Reset all filters">
            <svg  on:click={() => reset_filters()} class="shopify-buy__gallery-close" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="shopify-buy__gallery-close__el" d="M44 2.9325L2.9325 44" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="shopify-buy__gallery-close__el" d="M44 44L2.93251 2.9325" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </div>
      </div>

      
    </div>
  </div>
</section>
{/if}