const default_settings = {
  env: "development",
  debug: true,
  base_url: "http://localhost:3000",
  api_version: "1",
  api_token: "very-secret-string",
  ipstack_api_url: "http://api.ipstack.com/",
  ipstack_api_key: "",
};

export { default_settings };
