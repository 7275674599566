const local_settings = {
  env: "development",
  debug: true,
  base_url: "https://eating-our-way-to-extinction.myshopify.com",
  api_version: "2022-10",
  api_token: "c143d819ef5c27007870de73c83cdf22",
  ipstack_api_url: "https://api.ipstack.com/",
  ipstack_api_key: "8d130a93f921ed7852a4e3bd8c80b948",
};

export { local_settings };
