import App from "./App.svelte";

const shopListingBlock = document.querySelector("#shop-listing-block");
const cartBlock = document.querySelector("#cart-block");

const previewBlock = document.querySelector("#shop-listing-block__preview");
if (previewBlock != null) {
  previewBlock.classList.add("hidden");
}

let app = {};
let appElement = {};

if (shopListingBlock != null) {
  appElement = shopListingBlock;
} else if (cartBlock != null) {
  appElement = cartBlock;
}

app = new App({
  target: appElement,
});

export default app;
