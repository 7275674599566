<script>
  export let available_currencies;
  export let currency;
  export let currency_change_handler;
</script>

<div class="shopify-buy__currency-selector">
    <p class="mb-0">Currency:</p>
    <div class="shopify-buy__option-select-wrapper">
        <select class="shopify-buy__option-select__select" on:input={currency_change_handler} value={currency}>
            {#each available_currencies as value}
            <option {value}>{value}</option>
            {/each}
        </select>
    </div>
</div>
