<script>
import {
    onMount, tick, onDestroy
} from 'svelte';
import settings from "./config";
import ProductListItem from './ProductListItem.svelte';
import CurrencySelector from './CurrencySelector.svelte';
import MiniShoppingCart from './MiniShoppingCart.svelte';
import ShoppingCart from "./ShoppingCart.svelte";
import ProductListItemBook from './ProductListItemBook.svelte';
import ShopFilters from './ShopFilters.svelte'

// configuration ----------------------------------------------------------- //

const BASE_URL = settings.base_url;
const API_VERSION = settings.api_version;
const API_URL = BASE_URL + "/api/" + API_VERSION + "/graphql.json";
const API_TOKEN = settings.api_token;
const IPSTACK_URL = settings.ipstack_api_url;
const IPSTACK_KEY = settings.ipstack_api_key;
const STORAGE_KEY = "e2eshop";

// store ------------------------------------------------------------------- //

let currency_to_country = {
    USD: "US",
    GBP: "GB",
    EUR: "IE",
    CAD: "CA",
    NZD: "NZ",
    AUD: "AU",
};
let country_to_currency = {
    US: "USD",
    GB: "GBP",
    IE: "EUR",
    CA: "CAD",
    NZ: "NZD",
    AU: "AUD",
};
let payment_settings = {};
let country = "US";
let available_currencies = [
    "USD",
    "GBP",
    "EUR",
    "CAD",
    "NZD",
    "AUD",
];
let currency = "USD";
let collections = [];
let products = [];
let current_products = [];
let checkout = null;
let checkout_items = [];
let checkout_url = null;
let product_types = [];
let product_options = [];
let product_tags = [];
let active_filters = {};

let store = {
    country,
    currency,
    products,
    current_products,
    checkout,
    checkout_items,
    checkout_url,
};

let is_cart_visible = false;

// local storage ----------------------------------------------------------- //

const save_store_state = (store) => {
  if (window && "localStorage" in window) {
    store.country = country;
    store.currency = currency;
    store.products = products;
    store.current_products = current_products;
    store.checkout = checkout;
    store.checkout_items = checkout_items;
    store.checkout_url = checkout_url;
    SHOP.store = store;
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(store));
  }
};

const set_currency_by_ip = async () => {
  try {
    const country_code = await get_country_code();

    if (country_code in country_to_currency) {
      handle_currency_change({ target: { value: country_to_currency[country_code] }})
    }
  } catch (e) {
    console.log('Error happend while trying to locate the client: ', e);
  }
};

const load_store_state = async () => {
    if (window && "localStorage" in window) {
        const stored_data = window.localStorage.getItem(STORAGE_KEY);

        if (stored_data) {
            const saved_data = JSON.parse(stored_data);

            country = saved_data.country ? saved_data.country : country;
            currency = saved_data.currency ? saved_data.currency : currency;
            products = saved_data.products ? saved_data.products : products;

            filter_products_by_umbraco();

            if (saved_data.checkout) {
              const existing_checkout = await get_checkout(saved_data.checkout.id, currency);

              if (existing_checkout.completedAt) {
                await create_checkout(currency);
              } else {
                checkout = saved_data.checkout ? saved_data.checkout : checkout;
                checkout_items = saved_data.checkout_items ? saved_data.checkout_items : checkout_items;
                checkout_url = saved_data.checkout_url ? saved_data.checkout_url : checkout_url;
              }

              handle_currency_change({target: {value: currency}});
            }
        } else {
          set_currency_by_ip()
        }
    } else {
      set_currency_by_ip()
    }
};

// helper functions -------------------------------------------------------- //

const checkout_quantity = (items) => {
  return items.map(i => i.quantity).reduce((acc, q) => { return acc + q }, 0)
}

const make_fetch_options = (query) => {
    return {
        method: "post",
        headers: {
            "Content-Type": "application/graphql",
            "X-Shopify-Storefront-Access-Token": API_TOKEN,
        },
        body: query,
    };
};

const make_fetch_options_with_variables = (query) => {
    return {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Shopify-Storefront-Access-Token": API_TOKEN,
        },
        body: JSON.stringify(query),
    };
};

const api_request = async (options) => {
    try {
        const response = await fetch(API_URL, options);

        if (response.status < 300) {
            const data = await response.json();

            if ("errors" in data) {
                throw new Error("BAD REQUEST: ", data.errors);
            }

            return data;
        } else if (response.status > 399) {
            throw new Error("BAD REQUEST: ", response);
        }
    } catch (error) {
        console.error(error.message, error.stack);
    }
};

const decode_id = (encoded) => {
    return atob(encoded).split("/").pop();
};

const encode_id = (id, type) => {
    return btoa(`gid://shopify/${type}/${id}`);
};

const simplify = (data) => {
    if (typeof data === "object" && "edges" in data) {
        return data.edges.map((i) => simplify(i.node));
    } else if (Array.isArray(data)) {
        return data.map((i) => simplify(i));
    } else if (typeof data === "object" && Object.keys(data).length > 1) {
        Object.keys(data).forEach((key) => {
            data[key] = data[key] ? simplify(data[key]) : data[key];
        });
    }

    return data;
};

const get_country_code = async () => {
  const ip_address = await fetch("https://api.ipify.org?format=json")
                            .then(r => r.json())
                            .then(d => d.ip);
  const url = `${IPSTACK_URL}/${ip_address}?access_key=${IPSTACK_KEY}`
  const country_code = await fetch(url)
                              .then(r => r.json())
                              .then(d => d.country_code);
  return country_code;
};

const filter_products_by_umbraco = () => {
    if ("SHOPHelper" in window && window.SHOPHelper.currentProductIDs) {
        let products = [];

        window.SHOPHelper.currentProductIDs.map((id) => {
            let product = get_product_by_short_id(id);

            if (product) {
              product = add_currency_symbol(product)
              products.push(product);
            }
        });

        current_products = products;
    }
};

const add_currency_symbol = (product) => {
  let currencySymbol = "";

  switch (product.price.currencyCode) {
    case "GBP":
      currencySymbol = "£";
      break;
    case "EUR":
      currencySymbol = "€";
      break;
    default:
      currencySymbol = "$";
      break;
  }

  product.price.currencySymbol = currencySymbol;
  return product;
}

// payment settings -------------------------------------------------------- //

const get_payment_settings = async () => {
    const query = `query {
      shop {
        paymentSettings {
          countryCode
          currencyCode
          enabledPresentmentCurrencies
        }
      }
    }`;

    const response = await api_request(make_fetch_options(query));

    if (response) {
        const settings = response.data.shop.paymentSettings;

        payment_settings = settings;
        available_currencies = settings.enabledPresentmentCurrencies;
        country = settings.countryCode;
        selected_currency = [settings.currencyCode];
        currency = settings.currencyCode;
    }

    return settings;
};

// collections ------------------------------------------------------------- //

const collection_list_query = `{
  collections(first: 100) {
    edges {
      node {
        id
        title
        products(first: 100) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}`;

const get_collections = async () => {
    const options = make_fetch_options(collection_list_query);
    const response = await api_request(options);

    if (!!response) {
        collections = simplify(response.data.collections);
    }

    return collections;
};

// products ---------------------------------------------------------------- //

// get the first hundred products (currently there are 46)
const product_list_query = () => {
    const country = currency_to_country[currency];

    return `query ProductList @inContext(country: ${country}) {
      products(first:100) {
        edges {
          node {
            id
            title
            description
            descriptionHtml
            options(first:100) {
              name
              values
            }
            productType
            tags
            images(first:100) {
              edges {
                node {
                  originalSrc
                }
              }
            }
            variants(first:100) {
              edges {
                node {
                  title
                  id
                  price {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }`;
};

const get_products = async () => {
    const options = make_fetch_options(product_list_query());
    const response = await api_request(options);

    if (!!response) {
        products = simplify(response.data.products);
    }
};

const get_product = (id) => {
    if (products.length === 0) {
        console.error("Missing product list!");
        return null;
    }

    return products.find((p) => p.id === id);
};

const get_product_by_short_id = (id) => {
    const product_id = `gid://shopify/Product/${id}`
    //const product_id = encode_id(id, "Product");
    const product = get_product(product_id);

    if (product) {
        product.price = product.variants[0].price;
        product.selected_options = [];
    }

    return product;
};

const get_product_options = (product) => {
  return product.options.map(o => o.name);
}

const make_option_element_id = (name, id) => {
  return `#${name}-${id.replace(':', '').replaceAll('/', '')}`
}

const get_selected_option_values = (options, product_id) => {
  return options.map(name => {
    const selector = make_option_element_id(name, product_id);
    const element = document.querySelector(selector);
    return element ? element.value : null;
  });
}

const get_product_variant_by_title = (product, title) => {
  return product.variants.find(v => {
    if (v.title === title) {
      return v;
    }
  });
}

const get_product_variant = (product_id) => {
  const product = get_product(product_id);
  const options = get_product_options(product);
  const selected_options = get_selected_option_values(options, product_id);

  if (selected_options[0] === null) {
    return product.variants[0];
  }

  const option_title = selected_options.join(' / ');
  const variant = get_product_variant_by_title(product, option_title);

  return variant;
}

const get_product_types = (products) => {
  const ptypes = Array.from(new Set(products.map((p) => {
    if (p.productType) {
      return p.productType
    }
  })));

  return ptypes.filter((pt) => pt !== undefined)
}

const get_all_product_options = (products) => {
  const opts = {};

  products.map((p) => {
    if (p.options.length > 0) {
      p.options.map((o) => {
        opts[o.name] = opts[o.name] ? Array.from(new Set([...opts[o.name], ...o.values])) : o.values
      })
    }
  })

  return opts;
}

const filter_products_by_type = (products, types) => {
  return products.filter(p => {
    if (types.includes(p.productType)) {
      return p;
    }
  })
}

const filter_products_by_color = (products, colors) => {
  let filtered = [];

  products.forEach(p => {
    p.variants.forEach(pv => {
      const [color, _] = pv.title.split(' / ');

      // color match
      if (colors.includes(color)) {
        filtered.push(p);
      }
    });
  });

  return filtered;
}

const filter_products_by_size = (products, sizes) => {
  let filtered = [];

  products.forEach(p => {
    p.variants.forEach(pv => {
      const [_, size] = pv.title.split(' / ');

      // size match
      if (sizes.includes(size)) {
        filtered.push(p);
      }
    });
  });

  return filtered;
}

const filtered_products = (products, filters) => {
  // no filters yet
  if (Object.keys(filters).length < 1) {
    return products;
  }

  let filtered = products;

  // there are product type filters
  if ('types' in filters && filters.types.length > 0) {
    filtered = filter_products_by_type(filtered, filters.types)
  }

  // there are color filters
  if ('colors' in filters && filters.colors.length > 0) {
    filtered = filter_products_by_color(filtered, filters.colors)
  }

  if ('sizes' in filters && filters.sizes.length > 0) {
    filtered = filter_products_by_size(filtered, filters.sizes)
  }

  return Array.from(new Set(filtered));
}

// checkout ---------------------------------------------------------------- //

const make_line_item_from = (item_id, quantity) => {
    return {
        variantId: item_id,
        quantity: quantity
    };
};

const checkout_items_to_line_items = () => {
  let items = []
  if (checkout_items.length) {
    items = checkout_items.map((i) => {
      const line_item = make_line_item_from(i.variant.id, i.quantity);
      return line_item;
    })
  }

  return items;
};

const checkout_response = `{
  id
  webUrl
  currencyCode
  completedAt
  lineItemsSubtotalPrice {
    amount
    currencyCode
  }
  subtotalPrice {
    amount
    currencyCode
  }
  totalPrice {
    amount
    currencyCode
  }
  lineItems(first:100) {
    edges {
      node {
        id
        title
        quantity
        variant {
          id
          selectedOptions {
            name
            value
          }
          product {
            id
          }
          image {
            originalSrc
          }
          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
}`;

const get_checkout = async (checkout_id, currency_code) => {
  const country = currency_to_country[currency_code]
  const query = `query @inContext(country: ${country}) {
    node(id: "${checkout_id}") {
      id
      ... on Checkout ${checkout_response}
    }
  }`;

  const response = await api_request(make_fetch_options(query));

  if (response) {
    return simplify(response.data.node);
  }
}

const create_checkout = async (currency_code) => {
    const country = currency_to_country[currency_code]
    const query = `mutation  checkoutCreate($input: CheckoutCreateInput!) @inContext(country: ${country}){
      checkoutCreate(input: $input) {
        checkout ${checkout_response}
      }
    }`;
    const variables = {
        input: {
            presentmentCurrencyCode: currency_code,
            lineItems: checkout_items_to_line_items(),
        },
    };
    const options = make_fetch_options_with_variables({
        query,
        variables
    });

    const response = await api_request(options);

    if (!!response) {
        checkout = simplify(response.data.checkoutCreate.checkout);

        checkout_url = checkout.webUrl;
        checkout_items = checkout.lineItems;
    }

    return checkout;
};

const modify_checkout = async (action, query, variables) => {
    const toplevel = {
        create: "checkoutCreate",
        add: "checkoutLineItemsAdd",
        update: "checkoutLineItemsReplace",
        remove: "checkoutLineItemsRemove",
    };
    const body = {
        query,
        variables
    };
    const options = make_fetch_options_with_variables(body);
    const response = await api_request(options);

    if (!!response) {
        if ("errors" in response) {
            console.error(response.errors);
        }

        checkout = simplify(response.data[toplevel[action]].checkout);

        checkout_url = checkout.webUrl;
        checkout_items = checkout.lineItems;
    }

    return checkout;
};

const add_checkout_item = async (item_id, quantity) => {
    const checkout_id = checkout.id;
    const country = currency_to_country[currency]
    const query = `mutation checkoutLineItemsAdd($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) @inContext(country: ${country}) {
      checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
        checkout ${checkout_response}
        checkoutUserErrors {
          code
          field
          message
        }
      }
    }`;

    const line_item = make_line_item_from(item_id, quantity);
    const variables = {
        lineItems: [line_item],
        checkoutId: checkout_id,
    };

    return await modify_checkout("add", query, variables);
};

const update_checkout_item = async (item_id, quantity) => {
    const checkout_id = checkout.id;
    const items = checkout_items;
    const country = currency_to_country[currency]
    const query = `mutation checkoutLineItemsReplace($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) @inContext(country: ${country}) {
      checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
        checkout ${checkout_response}
        userErrors {
          code
          field
          message
        }
      }
    }`;
    const item_to_update = items.find((i) => i.id === item_id);
    item_to_update.quantity = quantity;
    const variables = {
        lineItems: items.map((i) =>
            make_line_item_from(i.variant.id, i.quantity)
        ),
        checkoutId: checkout_id,
    };

    return await modify_checkout("update", query, variables);
};

const remove_checkout_item = async (item_id) => {
    const checkout_id = checkout.id;
    const country = currency_to_country[currency]
    const query = `mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) @inContext(country: ${country}) {
      checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
        checkout ${checkout_response}
        checkoutUserErrors {
          code
          field
          message
        }
      }
    }`;
    const variables = {
        checkoutId: checkout_id,
        lineItemIds: [item_id],
    };

    return await modify_checkout("remove", query, variables);
};

// init ------------------------------------------------------------------- //

const load = async () => {
  await load_store_state();

  if (!collections.length) {
    await get_collections();
  }

  //if (!products.length) {
    await get_products();
    filter_products_by_umbraco();
  //}

  if (checkout === null) {
    await create_checkout(currency);
  }

  product_types = get_product_types(current_products);
  product_options = get_all_product_options(current_products);
};

const reload = async () => {
    await get_products();
    filter_products_by_umbraco();
    await create_checkout(currency);
    product_types = get_product_types(current_products);
    product_options = get_all_product_options(current_products);
};

// event handlers ---------------------------------------------------------- //
//
// IMPORTANT: save_store_state(store) need to be called in every case when the
// event handler changes the state of the store to make it possible for us to
// restore page state after page loads.

const add_product_to_cart = async (e) => {
    const id = e.target.dataset.product_id;
    const variant = get_product_variant(id)

    is_cart_visible = true;

    await add_checkout_item(variant.id, 1);

    await tick();

    save_store_state(store);
}

const add_one_to_cart = async (e) => {
    const id = e.currentTarget.dataset.variant_id;
    const quantity = e.currentTarget.dataset.quantity;

    is_cart_visible = true;

    await update_checkout_item(id, parseInt(quantity, 10) + 1);

    await tick();

    save_store_state(store);
}

const subtract_one_from_cart = async (e) => {
    const id = e.currentTarget.dataset.variant_id;
    const quantity = parseInt(e.currentTarget.dataset.quantity, 10);

    if (quantity === 1) {
      await remove_checkout_item(id);
    } else {
      await update_checkout_item(id, quantity - 1);
    }

    await tick();

    save_store_state(store);
}

const set_quantity_in_cart = async (e) => {
  const id = e.currentTarget.dataset.variant_id;
  const quantity = parseInt(e.currentTarget.value, 10);

  await update_checkout_item(id, quantity);

  await tick();

  save_store_state(store);
}

const toggle_cart_visibility = () => {
    is_cart_visible = !is_cart_visible
}

const handle_currency_change = async (e) => {
  currency = e.target.value;
  country = currency_to_country[currency];

  await tick();

  save_store_state(store);
  reload();
}

const handle_checkout = () => {
  save_store_state(store);
  window.location.href = checkout_url;
}

// init -------------------------------------------------------------------- //

const SHOP = {
    store,
    load,
    reload,
    get_checkout,
    handle_currency_change,
};

const init = async () => {
   jQuery(document).on("scripts-loaded", () => {
    window.SHOP = SHOP;
    SHOP.load();
   });
}

// lifecycle --------------------------------------------------------------- //

onMount(async () => {
    await init()
});

onDestroy(() => {
    save_store_state(store);
});

</script>

<div>
  {#if current_products.length > 0}
    <!-- product listing -->
    <section class="producttabs shop-listing">
      <div class="filter-list sp">
        {#if window.SHOPHelper.isTreeListing === "True"}
          <!-- tree page -->
          <div class="hero__full-w hero--trees">
            <img
              src={window.SHOPHelper.heroBackgroundImageUrl}
              alt={window.SHOPHelper.heroBackgroundImageAlt}
              class="hero__bg" />
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h2 class="filter-list-title">
                    {@html window.SHOPHelper.pageName}
                  </h2>
                  <p class="summary">{@html window.SHOPHelper.summary}</p>
                </div>
                <div class="col-12">
                  <CurrencySelector
                    {available_currencies}
                    {currency}
                    currency_change_handler={handle_currency_change} />
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              {#if current_products.length > 0}
                {#each filtered_products(current_products, active_filters) as product}
                  {#if window.SHOPHelper.isBookListing === "True"}
                    <ProductListItemBook
                      {product}
                      buy_handler={add_product_to_cart} />
                  {:else}
                    <ProductListItem
                      {product}
                      buy_handler={add_product_to_cart} />
                  {/if}
                {/each}
              {/if}
            </div>
            <!-- end of row -->
          </div>
        {:else}
          <!-- all other pages -->
          <div class="container">
            <div class="row">
              <div class="col-12 filter-list">
                <!--{#if window.SHOPHelper.hasFilters !== "True"}-->
                <h2 class="filter-list__title">
                  {@html window.SHOPHelper.pageName}
                </h2>
                <!--{/if}-->
                <CurrencySelector
                  {available_currencies}
                  {currency}
                  currency_change_handler={handle_currency_change} />
              </div>
            </div>

            <ShopFilters
              bind:active_filters
              {product_types}
              {product_options} />

            <div class="row row__products">
              {#if current_products.length > 0}
                {#each filtered_products(current_products, active_filters) as product}
                  {#if window.SHOPHelper.isBookListing === "True"}
                    <ProductListItemBook
                      {product}
                      buy_handler={add_product_to_cart} />
                  {:else}
                    <ProductListItem
                      {product}
                      buy_handler={add_product_to_cart} />
                  {/if}
                {/each}
              {/if}
            </div>
            <!-- end of row -->
          </div>
        {/if}
      </div>
    </section>
    <!-- end of product listing -->
  {/if}

  {#if checkout_items.length > 0}
    <MiniShoppingCart
      items_in_cart={checkout_quantity(checkout_items)}
      open_cart_handler={toggle_cart_visibility} />
  {/if}

  <ShoppingCart
    is_visible={is_cart_visible}
    items={checkout_items}
    {checkout}
    {checkout_url}
    close_handler={toggle_cart_visibility}
    increment_handler={add_one_to_cart}
    decrement_handler={subtract_one_from_cart}
    set_quantity_handler={set_quantity_in_cart}
    checkout_handler={handle_checkout} />
</div>
