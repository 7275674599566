<script>
  export let product;
  export let buy_handler;
  import { onMount } from "svelte";

  const option_id = (name, id) => {
    return `${name}-${id.replace(/=/g, '')}`
  }

  onMount(() => {
    jQuery(document).trigger("svelte-product-card-rendered--book", [product.id]);
  });
  
</script>

<!-- Books -->
<div class="filter-list-item {window.SHOPHelper.cssClass}">
  <div class="product shopify-buy-frame product shopify-buy-frame--books shopify-buy-frame--product shopify-buy__layout-vertical" style="max-width: 280px">
        <div class="shopify-buy__product__variant-img-wrapper shopify-buy__product__variant-img-wrapper--desktop">
          <!-- Desktop -->
          <div class="book-imgs--left">
            <a href="{product.images[0].originalSrc}" class="shopify-buy__product__img-link" data-shop-gallery-carousel-desktop={product.id} data-shop-gallery-carousel-index="0">
              <img class="shopify-buy__product__variant-img" alt={product.title} src="{product.images[0].originalSrc}" />
            </a>
          </div>
          
          <div class="book-imgs--right">
            {#if product.images.length > 1 }
            <a href="{product.images[1].originalSrc}" class="shopify-buy__product__img-link" data-shop-gallery-carousel-desktop={product.id} data-shop-gallery-carousel-index="1">
              <img class="shopify-buy__product__variant-img" alt={product.title} src="{product.images[1].originalSrc}" />
            </a>
            {/if}
            {#if product.images.length > 2 }
            <a href="{product.images[2].originalSrc}" class="shopify-buy__product__img-link" data-shop-gallery-carousel-desktop={product.id} data-shop-gallery-carousel-index="2">
              <img class="shopify-buy__product__variant-img" alt={product.title} src="{product.images[2].originalSrc}" />
            </a>
            {/if}
          </div>  
      </div>

      <div class="shopify-buy__product__variant-img-wrapper shopify-buy__product__variant-img-wrapper--mobile" data-shop-gallery-carousel-mobile={product.id}>
          <!-- Mobile -->
          {#each product.images as image}
            <img class="shopify-buy__product__variant-img" alt={product.title} src={image.originalSrc}>
          {/each}
      </div>

      <div class="shopify-buy__product__top-details">
        <h1 class="shopify-buy__product__title">{product.title}</h1>
        <p class="shopify-buy__product__title-underline"></p>

        <div class="shopify-buy__product-description">{@html product.descriptionHtml}</div>
      </div>

      <div class="shopify-buy__option-select-box">
        {#each product.options as option}
        {#if option.name !== "Title" }
        <div>
            <p>{option.name}</p>

            <div class="shopify-buy__option-select-wrapper">
                <select id={option_id(option.name, product.id)} class="shopify-buy__option-select__select">
                    {#each option.values as value}
                    <option {value}>{value}</option>
                    {/each}
                </select>
            </div>
        </div>
        {/if}
        {/each}
      </div>

      <div class="shopify-buy__product__price-wrapper">
          <p class="shopify-buy__product__price">
              <span>{product.price.currencySymbol}</span><span>{parseFloat(product.price.amount || "0").toFixed(2)}</span>
          </p>
      </div>

      <div class="shopify-buy__btn-wrapper shopify-buy__btn-wrapper__books">
          {#if window.SHOPHelper.disablePreviewButton === "False" }
          <button data-product_id={product.id} class="shopify-buy__btn" data-shop-gallery-carousel-desktop={product.id}>Preview</button>
          {/if}
          <button data-product_id={product.id} class="shopify-buy__btn" on:click={buy_handler}>Add to cart</button>
      </div>

      <!--div class="shopify-buy__quantity-select-wrapper shopify-buy__quantity-select-wrapper--books">
         <div>
             <p>QUANTITY:</p>
             <div class="shopify-buy__option-select-wrapper" data-element="option.wrapper">
                 <select class="shopify-buy__option-select__select" name="Colour" data-element="option.select">
                     <option selected="" value="1">1</option>
                 </select>
             </div>
         </div>
     </div-->

      <!-- Modal -->
     
        <div class="shopify-buy__gallery-bg" style="display: none;" data-shop-gallery-carousel-modal={product.id}>
          <div class="shopify-buy__gallery">
              <div class="shopify-buy__gallery-wrapper">
                <a href="#" data-shop-gallery-modal-close={product.id}>
                    <svg class="shopify-buy__gallery-close" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="shopify-buy__gallery-close__el" d="M44 2.9325L2.9325 44" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        <path class="shopify-buy__gallery-close__el" d="M44 44L2.93251 2.9325" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>

                  <p>{product.title}</p>
                  <div class="shopify-buy__gallery-carousel--main" data-shop-gallery-carousel-main={product.id}>
                    {#each product.images as image}
                      <img alt={product.title} src={image.originalSrc}>
                    {/each}
                  </div>   
                  
                  <div class="shopify-buy__gallery-carousel--thumbs" data-shop-gallery-carousel-thumbs={product.id}>
                    {#each product.images as image}
                      <img alt={product.title} src={image.originalSrc}>
                    {/each}
                  </div>
              </div>
          </div>
        </div>
      <!-- End of modal -->
  </div> <!-- end of product -->
</div> <!-- end of filter-list-item -->